<template>
    <div class="modal-footer">
        <div>
            <button class="btn-primary btn-transparent" @click="tertiary" v-if="footer.tertiaryButton">{{ footer.tertiaryButton.toLowerCase() }}</button>
        </div>
        <div class="flex">
            <button
              :class="secondaryClass"
              class="mr-4"
              @click="secondary"
              v-if="footer.secondaryButton"
            >
              {{ footer.secondaryButton.toLowerCase() }}
            </button>
            <button
              :class="primaryClass"
              @click.stop="primary"
              v-if="footer.primaryButton"
              :disabled="disablePrimary || primaryButtonDisabled"
            >
              {{ footer.primaryButton.toLowerCase() }}
            </button>
        </div>
    </div>
</template>

<script>
import ModalFooterMixin from "@/components/ui/modals/ModalFooterMixin";
import EventBus from "@/utils/EventBus";

export default {
    mixins: [ModalFooterMixin],
    props: {
        footer: {
            type: Object,
            required: false,
            default: () => {
                return {
                    primaryButton: 'Save',
                    secondaryButton: '',
                    tertiaryButton: ''
                }
            }
        },
        primary: {
            type: Function,
            required: false,
            default: () => { window.console.log('primary button clicked') }
        },
        secondary: {
            type: Function,
            required: false,
            default: () => { window.console.log('secondary button clicked') }
        },
        tertiary: {
            type: Function,
            required: false,
            default: () => { window.console.log('tertiary button clicked') }
        },
        primaryClass: {
            type: String,
            default: 'btn-primary btn-solid'
        },
        secondaryClass: {
            type: String,
            default: 'btn-primary'
        },
        disablePrimary: {
            type: Boolean,
            default: false
        }
    },
    data: () => {
        return {
            primaryButtonDisabled: false
        }
    },
    mounted() {
        EventBus.on('disable-primary-footer-button', () => {
            this.primaryButtonDisabled = true;
        })
    }
}
</script>
