export default {
  data: () => {
    return {
      position: 'sticky'
    }
  },
  methods: {
    setFooterPosition() {
      const modalBody = document.getElementById('modal-body');
      const modalBodyContent = document.getElementById('modal-body-container')?.firstChild;

      this.position = modalBody?.clientHeight > modalBodyContent?.clientHeight ? 'absolute' : 'sticky';
    }
  },
  mounted() {
    this.$nextTick( () => {
      // wait until all dom is loaded
      this.setFooterPosition();
    });

    window.addEventListener('resize', () => {
      this.setFooterPosition();
    });

    const modalBody = document.getElementById('modal-body');
    const modalFooter = modalBody?.querySelector('.modal-footer');

    if(modalFooter) {
      modalBody.classList.add('has-footer');
    }
  },
  beforeUnmount(){
    const modalBody = document.getElementById('modal-body');
    modalBody?.classList.remove('has-footer');
  },
}
