<template>
  <FinalField :name="name" :validate="validate" ref="field">
    <template v-slot="props">
      <div class="form-col-small">

        <label v-if="label" :for="name">{{ label }}</label>

        <text-input
          v-if="editMode"
          v-bind="$attrs"
          :value="props.value"
          :name="props.name"
          :class="[inputClass, {error: props.meta.error && props.meta.touched}]"
          v-on="props.events"
        />

        <text-input
          v-if="!editMode"
          v-bind="$attrs"
          :value="props.value"
          :class="inputClass"
          readOnly
        />

        <span
          class="form-hint form-error"
          v-if="
            (props.meta.error && props.meta.touched) ||
            (props.meta.submitError && !props.meta.dirtySinceLastSubmit)
          "
        >
          {{ props.meta.error || props.meta.submitError }}
        </span>
      </div>
    </template>
  </FinalField>
</template>

<script>
import { FinalField } from "vue-final-form";
import TextInput from '@/components/ui/TextInput';

export default {
  inheritAttrs: false,

  components: {
    FinalField,
    TextInput,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
    },

    validate: {
      type: [Function, Array],
      required: false,
    },

    editMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    fieldEvents() {
      return {
        input: (e) =>
          this.$refs.field.fieldState.change(this.parse(e.target.value)),
        blur: () => this.$refs.field.fieldState.blur(),
        focus: () => this.$refs.field.fieldState.focus(),
      };
    },
    inputType() {
      return this.type === "password" && this.showPassword ? "text" : this.type;
    },
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    textValue(value) {
      return value ?? "-";
    },
  },
};
</script>

<style scoped>
.eye-icon {
  @apply w-5 h-5 text-gray-500;
}
</style>
